.common-filter{
    .filter-item{
        min-width: 170px;
    }
    .filter-item-closure{
      width: 100%;
  }
    .submit-button{
        height: 56px;
        width: auto;
    }
    .submit-button-closure{
      height: 56px;
      width: 100%;
  }
    .submit-button-container {
        margin-left: auto;
    }
    .calendar-control{
        .react-calendar{
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 1px;
            border-radius: 5px;
            border-color: #c0c0c0;

            .react-calendar__tile--active{
                background: #3f51b5;
            }
        }
    }
}
