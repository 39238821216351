@import './loading.css';
@import './loading.scss';
@import './filter.scss';

.text-center { text-align: center; }
.red { color: red; }
.green { color: green; }
.bold { font-weight: bold;}

.MuiFormControlLabel-root, .MuiFormControl-root {
  height: 100%;
}

html, body, main {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
